import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea"
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { payeesSelectedFilters } from "./config";
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { validateNumbersOnly } from '../../../Common/Helper';

export default withUserContext(class Payees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSearchList: [],
      searchCancelToken: null,
      clearFilters: this.props?.clearFilters,
      sortBy: "Contact",
      payeesSelectedFilters: JSON.parse(JSON.stringify({ ...payeesSelectedFilters })),
      statusOptions: [{ label: "Select", value: null }, { label: "Active", value: "true" }, { label: "Inactive", value: "false" }],
      filterList: {
        payeeCompanyList: [],
        stateList: [],
        countryList: [],
      }
    }
  }

  componentDidMount() {
    document.title = 'Payees'
    if(this.props?.userContext?.active_tenant?.tenant_id) {
    this.fetchStaticData("lu_state", "stateList");
    this.fetchStaticData("lu_country", "countryList");
    }}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
      this.fetchStaticData("lu_state", "stateList");
      this.fetchStaticData("lu_country", "countryList");
    }
    if (this.props?.clearFilters != this.state.clearFilters) {
      let mockClear = this.props?.clearFilters
      this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Contact" })
    }
    if (this.props?.userContext.dashboardName === "Payees" && this.state.sortBy != this.props?.sortBy) {
      this.setState({ sortBy: this.props?.sortBy })
    }
  }

  fetchStaticData = (entity, node) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext.active_tenant.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
        formattedList.unshift({ label: "Select", value: null })
        this.updateListState(formattedList, node, 'filterList')
      },
        (err) => {
          console.log("Error in fetching static data:", err)
        })
  }

  fetchEntityList = (entity, config, node) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${entity}&searchString=null`,
      this.props.userContext.active_tenant.tenant_id
    )
      .then(
        (response) => {
          let formattedList = response.data?.map((item) => ({
            value: item[config.id],
            label: item[config.name],
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, node, "filterList");
        },
        (err) => {
          console.log("Error in fetching entity list:", err);
        }
      )
  }

  getPayeeDetails = (entity, config, node, searchString) => {
    if (this.props?.userContext?.active_tenant?.tenant_id) {
      let option = {
        "searchString": searchString,
      }
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
        .then(response => {
          let formattedList = response.data?.map((item) => ({
            value: item[config.id],
            text: item[config.name],

          }));
          this.updateListState(formattedList, node, "filterList");
        },
          (err) => {
            console.log("Error in fetching license data:", err)
          })
    }
  }

  updateListState = (value, node, list) => {
    this.setState(prevState => ({
      [list]: {
        ...prevState[list],
        [node]: value
      }
    }))
  }

  handleFilterListSelection = (type, val, name, node) => {
    let list = this.state.filterSearchList.filter(item => item.type !== type);
    this.setState({ filterSearchList: list });
    let filteredPayee = [...this.state.filterSearchList]
    let newFilterValue = {
      type: type,
      label: name,
      value: val,
      node: node
    }
    let check = filteredPayee.filter((item, index) => {
      if (item.type === type) {
        return filteredPayee[index] = newFilterValue

      }
    })
    if (check.length === 0) {
      filteredPayee.push(newFilterValue)
    }
    this.setState({ filterSearchList: filteredPayee })
    let payeeDetails = {
      payee_search_ids: filteredPayee.find((item) => item.type === "payeecompany")?.label || null,
      //payee_search_text: filteredPayee.find((item) => item.type === "payeecompany")?.label || null,
      tax_ssn_search_text: filteredPayee.find((item) => item.type === "tax ssn")?.label || null,
      vendor_search_text: filteredPayee.find((item) => item.type === "vendor")?.label || null,
      contact_name_search_text: filteredPayee.find((item) => item.type === "contact name")?.label || null,
      is_active_flag: filteredPayee.find((item) => item.type === "active inactive")?.value || null,
      dba_search_text: filteredPayee.find((item) => item.type === "dba")?.label || null,
      remit_search_text: filteredPayee.find((item) => item.type === "remit to")?.label || null,
      address_1_search_text: filteredPayee.find((item) => item.type === "address name")?.label || null,
      address_2_search_text: filteredPayee.find((item) => item.type === "street address")?.label || null,
      city_search_text: filteredPayee.find((item) => item.type === "city")?.label || null,
      email_search_text: filteredPayee.find((item) => item.type === "email")?.label || null,
      state_search_ids: filteredPayee.find((item) => item.type === "state")?.label || null,
      country_search_ids: filteredPayee.find((item) => item.type === "country")?.value || null,
      zip_search_text: filteredPayee.find((item) => item.type === "zipcode")?.label || null,
      phone_search_text: filteredPayee.find((item) => item.type === "phone")?.label || null,
      notes_search_text: filteredPayee.find((item) => item.type === "notes")?.label || null,
      order_by: null,
      order_by_clause: this.state.sortBy,
      page_count: this.props?.rowsPerPage,
      page_no: this.props?.page > 0 ? this.props?.page : 1
    }
    this.props.updateFilteredList(filteredPayee, payeeDetails, node)

  }
  render() {
    return (
      <div className="filterFieldsContainer">
        <MDBRow>
          <MDBCol md={3}>
            <div >Details</div>
            <MDBRow className="mt-5">
            <BasicTextField
                  placeholder={'- Payee Company -'}
                  value={this.props?.payeesSelectedFilters?.payeecompany || ''}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "payeecompany",
                      "payeecompany",
                      e.target.value,
                      "payeecompany"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "payeecompany",
                      "payeesSelectedFilters"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                  />
              {/* <SearchSelectField
                id="payee"
                placeholder="- Payee Company -"
                width="100%"
                searchSelect={true}
                multiple={false}
                detail_selected={this.props?.payeesSelectedFilters?.payeecompany ? {
                  value: this.props?.payeesSelectedFilters?.payeecompany,
                  text: this.state.filterList?.payeeCompanyList.find(item => item.value === this.props?.payeesSelectedFilters?.payeecompany)?.text
                } : null}
                options={this.state.filterList?.payeeCompanyList || []}
                valueSelected={(e, newValue) => {
                  let selectedShow = this.state.filterList?.payeeCompanyList?.find(item => item.value === (newValue != null ? newValue.value : null));
                  this.props.updateListState(newValue != null ? newValue.value : null, 'payeecompany', 'payeesSelectedFilters')
                  this.handleFilterListSelection("payeecompany", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "payeecompany")
                }}
                searchText={ev => {
                  // dont fire API if the user delete or not entered anything
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    this.getPayeeDetails("PAYEE", filterConfig.payeeCompany, "payeeCompanyList", ev.target.value);
                  }
                }}
              />               */}
            </MDBRow>
            <MDBRow className="lh-1">
              <MDBCol md={6}>
                <BasicTextField
                  id="tax"
                  placeholder="Tax ID/SSN"
                  value={this.props.payeesSelectedFilters?.taxSSN || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('tax ssn', 'taxSsn', e.target.value, 'taxSSN');
                    this.props.updateListState(e.target.value, 'taxSSN', 'payeesSelectedFilters')
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={6}>
                <BasicTextField
                  id="vendor"
                  placeholder="Vendor"
                  value={this.props.payeesSelectedFilters?.vendor || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('vendor', 'vendor', e.target.value, 'vendor');
                    this.props.updateListState(e.target.value, 'vendor', 'payeesSelectedFilters')
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={6} >
                <SelectField
                  id="status"
                  value={this.props.payeesSelectedFilters?.activeInactive}
                  options={this.state.statusOptions}
                  placeHolderText="Active/Inactive"
                  onChange={(e) => {
                      let selectedValue = this.state.statusOptions?.find(item => item.value === e.target.value);
                      this.props.updateListState(e.target.value, 'activeInactive', 'payeesSelectedFilters')
                      this.handleFilterListSelection('active inactive', selectedValue?.value, selectedValue?.label, 'activeInactive')
                  }}
               />
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md={7}>
            <MDBRow>
              <div>Payee</div>
              <MDBCol md={4}>
                <BasicTextField
                  id="firstName"
                  placeholder="Contact Name"
                  value={this.props.payeesSelectedFilters?.contactName || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('contact name', 'contact Name', e.target.value, 'contactName');
                    this.props.updateListState(e.target.value, 'contactName', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={4}>
                <BasicTextField
                  id="addressName"
                  placeholder="Address Name"
                  value={this.props.payeesSelectedFilters?.addressName || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('address name', 'address Name', e.target.value, 'addressName');
                    this.props.updateListState(e.target.value, 'addressName', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  placeholder="State"
                  id="state"
                  value={this.props.payeesSelectedFilters?.state || ""}
                  onChange={(e) => {
                    this.props.updateListState(e.target.value, 'state', 'payeesSelectedFilters')
                    this.handleFilterListSelection('state', "state", e.target.value, 'state')
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
                {/* <SelectField
                  id="state"
                  value={this.props.payeesSelectedFilters?.selectedState || ""}
                  options={this.state.filterList?.stateList || []}
                  placeHolderText="State"
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.stateList?.find(item => item.value === e.target.value);
                    this.props.updateListState(e.target.value, 'selectedState', 'payeesSelectedFilters')
                    this.handleFilterListSelection('state', selectedValue?.value, selectedValue?.label, 'selectedState')
                  }}
                /> */}
              </MDBCol>
            </MDBRow>
            <MDBRow className='m-t1'>
              <MDBCol md={4}>
                <BasicTextField
                  id="dba"
                  placeholder="DBA"
                  value={this.props.payeesSelectedFilters?.dBA || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('dba', 'dba', e.target.value, 'dBA');
                    this.props.updateListState(e.target.value, 'dBA', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={4}>
                <BasicTextField
                  id="streetAddress"
                  placeholder="Street Address"
                  value={this.props.payeesSelectedFilters?.streetAddress || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('street address', 'street Address', e.target.value, 'streetAddress');
                    this.props.updateListState(e.target.value, 'streetAddress', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={4}>
                <SelectField
                  id="Country"
                  value={this.props.payeesSelectedFilters?.selectedCountry || ""}
                  options={this.state.filterList?.countryList || []}
                  placeHolderText="Country"
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.countryList?.find(item => item.value === e.target.value);
                    this.props.updateListState(e.target.value, 'selectedCountry', 'payeesSelectedFilters')
                    this.handleFilterListSelection('country', selectedValue?.value, selectedValue?.label, 'selectedCountry')
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={4}>
                <BasicTextField
                  id="remitTo"
                  placeholder="Remit To"
                  value={this.props.payeesSelectedFilters?.remitTo || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('remit to', 'remit To', validateNumbersOnly(e.target.value) ? e.target.value : null, 'remitTo');
                    this.props.updateListState(validateNumbersOnly(e.target.value) ? e.target.value : null, 'remitTo', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  id="city"
                  placeholder="City"
                  value={this.props.payeesSelectedFilters?.city || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('city', 'city', e.target.value, 'city');
                    this.props.updateListState(e.target.value, 'city', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={1}></MDBCol>
              <MDBCol md={2}>
                <BasicTextField
                  id="zipCode"
                  placeholder="Zip Code"
                  value={this.props.payeesSelectedFilters?.zipcode || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('zipcode', 'zipcode', e.target.value, 'zipcode');
                    this.props.updateListState(e.target.value, 'zipcode', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={2}>
              </MDBCol>
              <MDBCol md={2}></MDBCol>
              <MDBCol md={4}>
                <BasicTextField
                  id="email"
                  placeholder="Email"
                  value={this.props.payeesSelectedFilters?.email || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('email', 'email', e.target.value, 'email');
                    this.props.updateListState(e.target.value, 'email', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  id="phone"
                  placeholder="Phone"
                  value={this.props.payeesSelectedFilters?.phone || ""}
                  onChange={(e) => {
                    this.handleFilterListSelection('phone', 'phone', e.target.value, 'phone');
                    this.props.updateListState(e.target.value, 'phone', 'payeesSelectedFilters')
                  }
                  }
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md={2} className='m-t2'>
            {/* <MDBRow>
              <MDBRow>
                <div className='m-t2'></div>
            </MDBRow>
              <MDBCol> */}
            <BasicTextArea
              rows={4}
              placeholder={"Notes"}
              value={this.props.payeesSelectedFilters?.notes || ""}
              onChange={(e) => {
                this.handleFilterListSelection('notes', 'notes', e.target?.value?.replace(/\n+$/, ""), 'notes');
                this.props.updateListState(e.target?.value?.replace(/\n+$/, ""), 'notes', 'payeesSelectedFilters')
              }
              }
              onKeyUp={(e) =>{
                if(e.keyCode === 13){
                    this.props?.handleFilterSearch()
                }
              }}
            />
            {/* </MDBCol>
            </MDBRow> */}
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
});