import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { filterConfig, licensorsFilter } from "./config";
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';

export default withUserContext(class Licensors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterSearchList: [],
            searchCancelToken: null,
            clearFilters: this.props?.clearFilters,
            sortBy: "Licensor",
            licensorsFilter: JSON.parse(JSON.stringify({ ...licensorsFilter })),
            filterList: {
                licensorList: [],
                stateList: [],
                countryList: [],
            },
            statusOptions: [{ label: "Select", value: null }, { label: "Active", value: "true" }, { label: "Inactive", value: "false" }],
            checkOptions: [{ label: "Select", value: null }, { label: "YES", value: "true" }, { label: "NO", value: "false" }]
        }
    }

    componentDidMount() {
        document.title = 'Licensors'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.fetchStaticData("lu_state", "stateList");
        this.fetchStaticData("lu_country", "countryList");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchStaticData("lu_state", "stateList");
            this.fetchStaticData("lu_country", "countryList");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Licensor" })
        }
        if (this.props?.userContext.dashboardName === "Licensors" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    fetchStaticData = (entity, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    fetchEntityList = (entity, config, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entity}&searchString=null`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        label: item[config.name],
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    this.updateListState(formattedList, node, "filterList");
                },
                (err) => {
                    console.log("Error in fetching entity list:", err);
                }
            )
    }

    getLicensorDetails = (entity, config, node, searchString) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": searchString
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    console.log("response", response)
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name],
                    }));
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    handleFilterListSelection = (type, val, name, node) => {
        let list = this.state.filterSearchList.filter(item => item.type !== type);
        this.setState({ filterSearchList: list });
        let filteredLicensors = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = filteredLicensors.filter((item, index) => {
            if (item.type === type) {
                return filteredLicensors[index] = newFilterValue

            }
        })
        if (check.length === 0) {
            filteredLicensors.push(newFilterValue)
        }
        this.setState({ filterSearchList: filteredLicensors })

        let licensorDetails = {

            licensor_search_ids: filteredLicensors.find((item) => item.type === "licensorName")?.label || null,
            is_provides_own_lic_flag: filteredLicensors.find((item) => item.type === "prvds own lic")?.value || null,
            is_active_flag: filteredLicensors.find((item) => item.type === "active inactive")?.value || null,
            reliance_ltr_rec_flag: filteredLicensors.find((item) => item.type === "reliance ltr")?.value || null,
            int_lic_w_change_flag: filteredLicensors.find((item) => item.type === "int lic")?.value || null,
            email_search_text: filteredLicensors.find((item) => item.type === "email")?.label || null,
            phone_search_text: filteredLicensors.find((item) => item.type === "phone")?.label || null,
            address_1_search_text: filteredLicensors.find((item) => item.type === "address")?.label || null,
            address_2_search_text: filteredLicensors.find((item) => item.type === "street")?.label || null,
            city_search_text: filteredLicensors.find((item) => item.type === "city")?.label || null,
            state_search_ids: filteredLicensors.find((item) => item.type === "state")?.label || null,
            country_search_ids: filteredLicensors.find((item) => item.type === "country")?.value || null,
            zip_search_text: filteredLicensors.find((item) => item.type === "zipcode")?.label || null,
            notes_search_text: filteredLicensors.find((item) => item.type === "notes")?.label  || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: this.props?.rowsPerPage,
            page_no: this.props?.page > 0 ? this.props?.page : 1
        }
        this.props.updateFilteredList(filteredLicensors, licensorDetails, node)

    }

    render() {
        return (
            <div className="filterFieldsContainer">
                <MDBRow>
                    <MDBCol md={3}>
                        <MDBCol md={10} className="mt-lh-1">
                            <BasicTextField
                                placeholder={'- Licensor Name -'}
                                value={this.props?.licensorSelectedFilters?.licensorName || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection(
                                        "licensorName",
                                        "licensorName",
                                        e.target.value,
                                        "licensorName"
                                    );
                                    this.props.updateListState(
                                        e.target.value,
                                        "licensorName",
                                        "licensorSelectedFilters"
                                    );
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                                 />
                            {/* <SearchSelectField
                                id="licensorName"
                                placeholder="- Licensor Name -"
                                width="100%"
                                searchSelect={true}
                                multiple={false}
                                detail_selected={this.props?.licensorSelectedFilters?.licensorName ? {
                                    value: this.props?.licensorSelectedFilters?.licensorName,
                                    text: this.state.filterList?.licensorList.find(item => item.value === this.props?.licensorSelectedFilters?.licensorName)?.text
                                } : null}
                                options={this.state.filterList?.licensorList || []}
                                valueSelected={(e, newValue) => {
                                    let selectedShow = this.state.filterList?.licensorList?.find(item => item.value === (newValue != null ? newValue.value : null));
                                    this.props.updateListState(newValue != null ? newValue.value : null, 'licensorName', 'licensorSelectedFilters')
                                    this.handleFilterListSelection("licensorName", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "licensorName")
                                }}
                                searchText={ev => {
                                    // dont fire API if the user delete or not entered anything
                                    if (ev.target.value !== "" || ev.target.value !== null) {
                                        this.getLicensorDetails("LICENSOR", filterConfig.licensorName, "licensorList", ev.target.value);
                                    }
                                }}
                            /> */}
                        </MDBCol>
                        <MDBRow className="mt-lh-1">
                            <MDBCol md={6} >
                                <SelectField
                                    id="prvdLicense"
                                    value={this.props.licensorSelectedFilters?.prvdsOwnLic}
                                    options={this.state.checkOptions}
                                    placeHolderText="Prvds. Own Lic."
                                    onChange={(e) => {
                                        let selectedValue = this.state.checkOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'prvdsOwnLic', 'licensorSelectedFilters')
                                        this.handleFilterListSelection('prvds own lic', selectedValue?.value, selectedValue?.label, 'prvdsOwnLic')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={6} className="lh-1">
                                <SelectField
                                    id="status"
                                    value={this.props.licensorSelectedFilters?.activeInactive}
                                    options={this.state.statusOptions}
                                    placeHolderText="Active/Inactive"
                                    onChange={(e) => {
                                        let selectedValue = this.state.statusOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'activeInactive', 'licensorSelectedFilters')
                                        this.handleFilterListSelection('active inactive', selectedValue?.value, selectedValue?.label, 'activeInactive')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-lh-1">
                            <MDBCol md={6}>
                                <SelectField
                                    id="reliance"
                                    value={this.props.licensorSelectedFilters?.relianceLtr}
                                    options={this.state.checkOptions}
                                    placeHolderText="Reliance Ltr. Rcvr"
                                    onChange={(e) => {
                                        let selectedValue = this.state.checkOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'relianceLtr', 'licensorSelectedFilters')
                                        this.handleFilterListSelection('reliance ltr', selectedValue?.value, selectedValue?.label, 'relianceLtr')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <SelectField
                                    id="intChange"
                                    value={this.props.licensorSelectedFilters?.intLic}
                                    options={this.state.checkOptions}
                                    placeHolderText="Int. Lic. w/changes"
                                    onChange={(e) => {
                                        let selectedValue = this.state.checkOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'intLic', 'licensorSelectedFilters')
                                        this.handleFilterListSelection('int lic', selectedValue?.value, selectedValue?.label, 'intLic')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={2} className="mt-lh-1">
                        <MDBCol>
                            <BasicTextField
                                id="email"
                                placeholder="Email"
                                value={this.props.licensorSelectedFilters?.email || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection('email', 'email', e.target.value, 'email');
                                    this.props.updateListState(e.target.value, 'email', 'licensorSelectedFilters')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                        <MDBCol md={8} className="mt-lh-1">
                            <BasicTextField
                                id="phone"
                                placeholder="Phone"
                                value={this.props.licensorSelectedFilters?.phone || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection('phone', 'phone', e.target.value, 'phone');
                                    this.props.updateListState(e.target.value, 'phone', 'licensorSelectedFilters')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                    </MDBCol>
                    <MDBCol md={2} className="mt-lh-1">
                        <MDBCol>
                            <BasicTextField
                                id="address"
                                placeholder="Address Name"
                                value={this.props.licensorSelectedFilters?.address || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection('address', 'address', e.target.value, 'address');
                                    this.props.updateListState(e.target.value, 'address', 'licensorSelectedFilters')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                        <MDBCol className="mt-lh-1">
                            <BasicTextField
                                id="street"
                                placeholder="Street Name"
                                value={this.props.licensorSelectedFilters?.street || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection('street', 'street', e.target.value, 'street');
                                    this.props.updateListState(e.target.value, 'street', 'licensorSelectedFilters')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                        <MDBCol md={8} className="mt-lh-1">
                            <BasicTextField
                                id="city"
                                placeholder="City"
                                value={this.props.licensorSelectedFilters?.city || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection('city', 'city', e.target.value, 'city');
                                    this.props.updateListState(e.target.value, 'city', 'licensorSelectedFilters')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                    </MDBCol>
                    <MDBCol md={2} className="mt-lh-1">
                        <MDBCol>
                            {/* <SelectField
                                id="state"
                                value={this.props.licensorSelectedFilters?.state}
                                options={this.state.filterList?.stateList}
                                placeHolderText="State"
                                onChange={(e) => {
                                    let selectedValue = this.state.filterList?.stateList?.find(item => item.value === e.target.value);
                                    this.props.updateListState(e.target.value, 'state', 'licensorSelectedFilters')
                                    this.handleFilterListSelection('state', selectedValue?.value, selectedValue?.label, 'state')
                                }}
                            /> */}
                            <BasicTextField
                                placeholder="State"
                                id="state"
                                value={this.props.licensorSelectedFilters?.state || ""}
                                onChange={(e) => {
                                    this.props.updateListState(e.target.value, 'state', 'licensorSelectedFilters')
                                    this.handleFilterListSelection('state', "state", e.target.value, 'state')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                        <MDBCol className="mt-lh-1">
                            <SelectField
                                id="country"
                                value={this.props.licensorSelectedFilters?.country}
                                options={this.state.filterList?.countryList}
                                placeHolderText="Country"
                                onChange={(e) => {
                                    let selectedValue = this.state.filterList?.countryList?.find(item => item.value === e.target.value);
                                    this.props.updateListState(e.target.value, 'country', 'licensorSelectedFilters')
                                    this.handleFilterListSelection('country', selectedValue?.value, selectedValue?.label, 'country')
                                }}
                            />
                        </MDBCol>
                        <MDBCol md={8} className="mt-lh-1">
                            <BasicTextField
                                id="zipcode"
                                placeholder="Zip Code"
                                value={this.props?.licensorSelectedFilters?.zipcode || ''}
                                onChange={(e) => {
                                    this.handleFilterListSelection('zipcode', 'zipcode', e.target.value, 'zipcode');
                                    this.props.updateListState(e.target.value, 'zipcode', 'licensorSelectedFilters')
                                }}
                                onKeyUp={(e) =>{
                                    if(e.keyCode === 13){
                                        this.props?.handleFilterSearch()
                                    }
                                }}
                            />
                        </MDBCol>
                    </MDBCol>
                    <MDBCol md={3} className="mt-lh-1">
                        <BasicTextArea
                            rows={3}
                            placeholder={"Notes"}
                            value={this.props.licensorSelectedFilters?.notes}
                            onChange={(e) => {
                                this.handleFilterListSelection('notes', 'notes', e.target?.value?.replace(/\n+$/, ""), 'notes');
                                this.props.updateListState(e.target?.value?.replace(/\n+$/, ""), 'notes', 'licensorSelectedFilters')
                            }}
                            onKeyUp={(e) =>{
                                if(e.keyCode === 13){
                                    this.props?.handleFilterSearch()
                                }
                            }}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
});