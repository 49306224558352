import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { typeOptions, peopleFilter, filterConfig } from './config';
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import '../DashboardContainer.scss';

export default withUserContext(class PeopleDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterSearchList: [],
            clearFilters: this.props?.clearFilters,
            sortBy: "Name",
            peopleFilter: JSON.parse(JSON.stringify({ ...peopleFilter })),
            selectedShowValue:null,
            filterList: {
                stateList: [],
                countrytList: [],
                showOptions: [],
                typeOptions: typeOptions,
                seasonList: [],
                showBasedSeasonList:[]
            }
        }
    }

    componentDidMount() {
        document.title = 'People'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.fetchStaticData("lu_state", "stateList");
        this.fetchStaticData("lu_country", "countrytList");
        this.getEntity('SEASON', filterConfig.season, 'seasonList');
        this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchStaticData("lu_state", "stateList");
            this.fetchStaticData("lu_country", "countrytList");
            this.getEntity('SEASON', filterConfig.season, 'seasonList');
            this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({clearFilters: mockClear, filterSearchList: [], sortBy: "Name" })
        }
        if(this.props?.userContext.dashboardName === "People" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy})
        }
    }

    getEntity = (entity, config, node) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        label: item[config.name],
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }

    getShows = (entity, config, node, value) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": value
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${value}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name],
                    }));
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
            this.props.updateListState(
                null,
                "season",
                "peopleFilter"
              );
              this.handleFilterListSelection(
                "season",
                null,
                null,
                "season"
              );
            clearTrackService
            .getData(
              Constants.ClearTrackServiceBaseUrl +
              `/seasonsBasedOnShows?show_id=${newValue.value}`,
              this.props?.userContext?.active_tenant?.tenant_id
            ).then(
              (response) => {
                let selectedShow = response?.data[0]?.seasons?.find(
                  (item) => response?.data[0]?.show_id === newValue.value
                )?.show_season_id;
                let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                  (item, index, data) =>
                    index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                ) : [];
                let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                  value: item.season_id,
                  label: item.season_name,
                })) : [];
                formattedList.unshift({ label: "Select", value: null })
                if (typeof (selectedShow) !== 'undefined') {
                  this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                } else {
                  this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                }
              })
            .catch(error => {
              console.log("error--", error)
            })
        
    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    fetchStaticData = (entity, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    handleFilterListSelection = (type, val, name, node) => {
        let list = this.state.filterSearchList.filter(item => item.type !== type);
        this.setState({ filterSearchList: list });
        let filteredPeopleList = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }

        let check = filteredPeopleList.filter((item, index) => {
            if (item.type === type) {
                return filteredPeopleList[index] = newFilterValue
            }
        })
        if (check.length === 0) {
            filteredPeopleList.push(newFilterValue)
        }

        this.setState({
            filterSearchList: filteredPeopleList
        })
        let peopleDetails = {
            type_search_ids: filteredPeopleList.find((item) => item.type === "type")?.value || null,
            full_name_search_text: filteredPeopleList.find((item) => item.type === "fullName")?.label || null,
            email_search_text: filteredPeopleList.find((item) => item.type === "email")?.label || null,
            work_phone_search_text: filteredPeopleList.find((item) => item.type === "workPhone")?.label || null,
            cell_phone_search_text: filteredPeopleList.find((item) => item.type === "cellPhone")?.label || null,
            address_1_search_text: filteredPeopleList.find((item) => item.type === "addressName")?.label || null,
            address_2_search_text: filteredPeopleList.find((item) => item.type === "streetAddress")?.label || null,
            city_search_text: filteredPeopleList.find((item) => item.type === "city")?.label || null,
            state_search_ids: filteredPeopleList.find((item) => item.type === "state")?.label || null,
            country_search_ids: filteredPeopleList.find((item) => item.type === "selectedCountry")?.value || null,
            zip_search_text: filteredPeopleList.find((item) => item.type === "zipcode")?.label || null,
            show_search_ids: filteredPeopleList.find((item) => item.type === "show")?.value || null,
            season_search_ids: filteredPeopleList.find((item) => item.type === "season")?.value || null,
            show_year_search_text:filteredPeopleList.find((item) => item.type === "Showyr")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: 100,
            page_no: 1,
        }
        this.props.updateFilteredList(filteredPeopleList, peopleDetails, type)
    }

    render() {
        return (
            <div className='PeopleContainer filterFieldsContainer'>
                <MDBRow>
                    <MDBCol md={9}>
                        <h6>Details</h6>
                        <MDBRow>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id="type"
                                            placeHolderText="- Type -"
                                            value={this.props?.peopleFilter?.type || null}
                                            options={this.state.filterList?.typeOptions || []}
                                            onChange={(e) => {
                                                let selectedValue = this.state.filterList?.typeOptions?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'type', 'peopleFilter')
                                                this.handleFilterListSelection('type', selectedValue?.value, selectedValue?.label, 'selectedValue')
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <BasicTextField
                                        id="fullName"
                                        placeholder="Full Name"
                                        value={this.props.peopleFilter?.fullName || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('fullName', 'fullName', e.target.value, 'fullName');
                                            this.props.updateListState(e.target.value, 'fullName', 'peopleFilter');
                                        }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                            <MDBRow>
                                    <BasicTextField
                                        id="company"
                                        placeholder="Company"
                                        value={this.props?.peopleFilter?.company || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('company', 'company', e.target.value, 'company');
                                            this.props.updateListState(e.target.value, 'company', 'peopleFilter');
                                        }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <BasicTextField
                                        id="email"
                                        placeholder="Email"
                                        value={this.props?.peopleFilter?.email || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('email', 'email', e.target.value, 'email');
                                            this.props.updateListState(e.target.value, 'email', 'peopleFilter');
                                        }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="workPhone"
                                            placeholder="WorkPhone"
                                            value={this.props?.peopleFilter?.workPhone || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('workPhone', 'workPhone', e.target.value, 'workPhone');
                                                this.props.updateListState(e.target.value, 'workPhone', 'peopleFilter');
                                            }
                                            }
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}

                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <BasicTextField
                                            id="cellPhone"
                                            placeholder="Cell phone"
                                            value={this.props?.peopleFilter?.cellPhone || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('cellPhone', 'cellPhone', e.target.value, 'cellPhone');
                                                this.props.updateListState(e.target.value, 'cellPhone', 'peopleFilter');
                                            }
                                            }
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <BasicTextField
                                        id="addressName"
                                        placeholder="Address Name"
                                        value={this.props?.peopleFilter?.addressName || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('addressName', 'addressName', e.target.value, 'addressName');
                                            this.props.updateListState(e.target.value, 'addressName', 'peopleFilter');
                                        }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <BasicTextField
                                        id="streetAddress"
                                        placeholder="Street Address"
                                        value={this.props?.peopleFilter?.streetAddress || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('streetAddress', 'streetAddress', e.target.value, 'streetAddress');
                                            this.props.updateListState(e.target.value, 'streetAddress', 'peopleFilter');
                                        }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="city"
                                            placeholder="City"
                                            value={this.props?.peopleFilter?.city || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('city', 'city', e.target.value, 'city');
                                                this.props.updateListState(e.target.value, 'city', 'peopleFilter');
                                            }
                                            }
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <BasicTextField
                                            placeholder="State"
                                            id="state"
                                            value={this.props.peopleFilter?.state || ""}
                                            onChange={(e) => {
                                            this.props.updateListState(e.target.value, 'state', 'peopleFilter')
                                            this.handleFilterListSelection('state', "state", e.target.value, 'state')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <SelectField
                                        id="country"
                                        placeHolderText="-Country-"
                                        value={this.props.peopleFilter?.selectedCountry || ""}
                                        options={this.state.filterList?.countrytList || []}
                                        onChange={(e) => {
                                            let selectedValue = this.state.filterList?.countrytList?.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'selectedCountry', 'peopleFilter')
                                            this.handleFilterListSelection('selectedCountry', selectedValue?.value, selectedValue?.label, 'selectedCountry')
                                        }}

                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="zipcode"
                                            placeholder="zipcode"
                                            value={this.props?.peopleFilter?.zipcode || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('zipcode', 'zipcode', e.target.value, 'zipcode');
                                                this.props.updateListState(e.target.value, 'zipcode', 'peopleFilter');
                                            }
                                            }
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBCol>
                    <MDBCol md={3}>
                        <MDBRow>
                            <MDBCol md={12}>
                                <h6>Show</h6>
                            </MDBCol>
                            <MDBCol md={12}>
                                <SearchSelectField
                                    id={"program"}
                                    placeholder={'- Show -'}
                                    options={this.state.filterList?.showOptions || []}
                                    searchSelect={true}

                                    width={'100%'}
                                    searchText={ev => {
                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                            this.getShows("SHOW", filterConfig.show, "showOptions", ev.target.value);
                                        }
                                    }}
                                    detail_selected={this.props?.peopleFilter?.show ? this.state.selectedShowValue : null}
                                    valueSelected={(e, newValue) => {
                                        if (newValue) {
                                            this.setState({selectedShowValue:newValue})
                                            this.selectedShowBasedSeason(newValue)
                                            //let selectedShow = this.state.filterList?.showOptions?.find(item => item.value === newValue.value);
                                            this.props.updateListState(newValue?newValue?.value:null, 'show', 'peopleFilter')
                                            this.handleFilterListSelection("show", newValue?newValue?.value:null, newValue?newValue?.text:null, "show")
                                        }else {
                                            this.setState({ selectedShowValue: null }, () => {
                                                if (!this.state.selectedShowValue) {
                                                    this.props.updateListState(null, 'season', 'peopleFilter')
                                                    this.handleFilterListSelection('season', null, null, 'season')
                                                }
                                            } )
                                            this.props.updateListState(null, 'show', 'peopleFilter')
                                            this.handleFilterListSelection("show", null, null, "show") 
                                        }
                                    }
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="lh-1">
                            <MDBCol md={6}>
                                <SelectField
                                    id="Season"
                                    placeHolderText="-Season-"
                                    value={this.props.peopleFilter?.season || ""}
                                    options={this.props?.peopleFilter?.show?this.state.filterList?.showBasedSeasonList:this.state.filterList?.seasonList || []}
                                    onChange={(e) => {
                                        let selectedSeason = (this.props?.peopleFilter?.show?this.state.filterList?.showBasedSeasonList:this.state.filterList?.seasonList)?.find(item => item.value === e.target.value);
                                        this.handleFilterListSelection('season', selectedSeason?.value, selectedSeason?.label, 'season');
                                        this.props.updateListState(e.target.value, 'season', 'peopleFilter');
                                    }
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicTextField
                                    id="Showyr"
                                    placeholder="Show Yr."
                                    value={this.props?.peopleFilter?.Showyr || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('Showyr', 'Showyr', e.target.value, 'Showyr');
                                        this.props.updateListState(e.target.value, 'Showyr', 'peopleFilter');
                                    }
                                    }
                                    onKeyUp={(e) =>{
                                        if(e.keyCode === 13){
                                            this.props?.handleFilterSearch()
                                        }
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}
)