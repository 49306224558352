import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import DateField from "../../SharedComponents/DateField/DateField"
import TimePicker from 'rc-time-picker';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import clearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from "../../../constants/constants";
import { filterConfig, budgetFilter } from './Config';
import axios from 'axios';
import InputAdornment from '@material-ui/core/InputAdornment';

export default withUserContext(class Budget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchCancelToken: null,
            filterSearchList: [],
            clearFilters: this.props?.clearFilters,
            budgetSelectedFilters: JSON.parse(JSON.stringify({ ...budgetFilter })),
            sortBy: "Show",
            selectedShowValue: null,
            filterList: {
                showList: [],
                seasonList: [],
                pilotAndSeriesList: [],
                unionList: [],
                showBasedSeasonList: []
            },
            pilotAndSeriesList: [{ label: "PILOT", value: "1" }, { label: "SERIES", value: "0" }],
            gtOptionsList: [{ label: "gt", value: ">" },
            { label: "lt", value: "<" }, { label: "gt/eq", value: ">=" }, { label: "lt/eq", value: "<=" }
            ],
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = 'Budget'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.fetchEntityList('SEASON', filterConfig.season, 'seasonList')
        this.fetchStaticData("lu_union", "unionList");
        this.getShowDetails("SHOW", filterConfig.show, "showList", "");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchEntityList('SEASON', filterConfig.season, 'seasonList')
            this.fetchStaticData("lu_union", "unionList");
            this.getShowDetails("SHOW", filterConfig.show, "showList", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Show" })
        }
        if (this.props?.userContext.dashboardName === "TV Creative Music Budget" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    fetchStaticData = (entity, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }


    fetchEntityList = (entity, config, node) => {
        this.setState({ isLoading: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    value: item[config.id],
                    label: item[config.name],
                }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
                this.setState({ isLoading: false })
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching entity list:", err)
                })
    }

    getShowDetails = (entity, config, node, searchString) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": searchString
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name],
                    }));
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
        this.props.updateListState(null, 'season', 'budgetSelectedFilters')
        this.handleFilterListSelection('season', null, null, 'season')
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/seasonsBasedOnShows?show_id=${newValue.value}`,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    let selectedShow = response?.data[0]?.seasons?.find(
                        (item) => response?.data[0]?.show_id === newValue.value
                    )?.show_season_id;
                    let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                        (item, index, data) =>
                            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                    ) : [];
                    let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                        value: item.season_id,
                        label: item.season_name,
                    })) : [];
                    let seasonBasedEpisodes = response?.data[0]?.seasons;
                    this.setState({ seasonBasedEpisodes })
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                    }
                })
            .catch(error => {
                console.log("error--", error)
            })
    }

    handleFilterListSelection = (type, val, name, node) => {
        let list = this.state.filterSearchList.filter(item => item.type !== type);
        this.setState({ filterSearchList: list });
        let filteredBudget = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = filteredBudget.filter((item, index) => {
            if (item.type === type) {
                return filteredBudget[index] = newFilterValue
            }
        })
        if (check.length === 0) {
            filteredBudget.push(newFilterValue)
        }
        this.setState({ filterSearchList: filteredBudget })
        let budgetDetails = {
            show_search_ids: filteredBudget.find((item) => item.type === "show")?.value || null,
            season_search_ids: filteredBudget.find((item) => item.type === "season")?.value || null,
            show_year_search_text: filteredBudget.find((item) => item.type === "showYr")?.label || null,
            episode_title_search_text: filteredBudget.find((item) => item.type === "episodeName")?.value || null,
            episode_no_search_text: filteredBudget.find((item) => item.type === "episode")?.label || null,
            description_search_text: filteredBudget.find((item) => item.type === "description")?.label || null,
            union_search_ids: filteredBudget.find((item) => item.type === "union")?.value || null,
            pilot_search_ids: filteredBudget.find((item) => item.type === "pilotOther")?.value || null,
            shoot_date_search_text: filteredBudget.find((item) => item.type === "shootDate")?.label || null,
            record_date_search_text: filteredBudget.find((item) => item.type === "recordingDate")?.label || null,
            location_search_text: filteredBudget.find((item) => item.type === "location")?.label || null,
            gt1_search_ids: filteredBudget.find((item) => item.type === "gt1")?.value || null,
            performer_cost_search_text: filteredBudget.find((item) => item.type === "performerCost")?.label || null,
            gt2_search_ids: filteredBudget.find((item) => item.type === "gt2")?.value || null,
            record_cost_search_text: filteredBudget.find((item) => item.type === "recordCost")?.label || null,
            gt3_search_ids: filteredBudget.find((item) => item.type === "gt3")?.value || null,
            session_cost_search_text: filteredBudget.find((item) => item.type === "sessionCost")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: 10,
            page_no: 1
        }
        this.props.updateFilteredList(filteredBudget, budgetDetails, type)
        console.log("props", this.props)
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };

    render() {
        let { budgetSelectedFilters } = this.props;
        let { budgetFields } = this.props;
        return (
            <div className='filterFieldsContainer MusicEditorContainer'>
                <MDBRow>
                    <MDBCol md={9}>
                        <MDBRow>
                            <MDBCol md={3}>
                                <MDBRow className="mt-5">
                                    <SearchSelectField
                                        id="show"
                                        placeholder="- Show -"
                                        width="100%"
                                        searchSelect={true}
                                        multiple={false}
                                        detail_selected={this.props?.budgetSelectedFilters?.show ? this.state.selectedShowValue : null}
                                        options={this.state.filterList?.showList || []}
                                        valueSelected={(e, newValue) => {
                                            if (newValue) {
                                                this.setState({ selectedShowValue: newValue })
                                                //let selectedShow = this.state.filterList?.showList?.find(item => item.value === (newValue != null ? newValue.value : null));
                                                this.selectedShowBasedSeason(newValue)
                                                this.props.updateListState(newValue != null ? newValue.value : null, 'show', 'budgetSelectedFilters')
                                                this.handleFilterListSelection("show", (newValue != null ? newValue?.value : null), newValue != null ? newValue?.text : null, "show")

                                            } else {
                                                this.setState({ selectedShowValue: null }, () => {
                                                    if (!this.state.selectedShowValue) {
                                                        this.props.updateListState(null, 'season', 'budgetSelectedFilters')
                                                        this.handleFilterListSelection('season', null, null, 'season')
                                                    }
                                                })
                                                this.props.updateListState(null, 'show', 'budgetSelectedFilters')
                                                this.handleFilterListSelection("show", null, null, "show")

                                            }
                                        }}
                                        searchText={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                this.getShowDetails("SHOW", filterConfig.show, "showList", ev.target.value);
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow className="lh-1">
                                    <MDBCol md={6}>
                                        <SelectField
                                            id="season"
                                            value={this.props.budgetSelectedFilters?.season || null}
                                            options={this.props?.budgetSelectedFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList || []}
                                            placeHolderText="- Season -"
                                            onChange={(e) => {
                                                let selectedValue = (this.props?.budgetSelectedFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList)?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'season', 'budgetSelectedFilters')
                                                this.handleFilterListSelection('season', selectedValue?.value, selectedValue?.label, 'season')
                                                let selectedShowSeason =
                                                    this.state.seasonBasedEpisodes?.find(
                                                        (item) => item.season_id === e.target.value
                                                    )?.show_season_id;
                                                this.setState(
                                                    { show_season_id: selectedShowSeason },
                                                    () => {
                                                        this.getShowBasedEpisodes(this.state.show_season_id);
                                                    }
                                                );
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol md={4}>
                                        <BasicTextField
                                            id="showYr"
                                            placeholder="Show Yr"
                                            value={this.props.budgetSelectedFilters?.showYr || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('showYr', 'showYr', e.target.value, 'showYr');
                                                this.props.updateListState(e.target.value, 'showYr', 'budgetSelectedFilters')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2}></MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow id="episode_Name">
                                    <SelectField
                                        id="episode"
                                        // label={"Seasons"}
                                        placeHolderText="- Episode Name -"
                                        options={this.props?.budgetSelectedFilters?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption || []}
                                        width="80%"
                                        value={this.props?.budgetSelectedFilters?.episodeName || null}
                                        onChange={(e) => {
                                            let selectedValue = (this.props?.budgetSelectedFilters?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption)?.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'episodeName', 'budgetSelectedFilters')
                                            this.handleFilterListSelection('episodeName', selectedValue?.value, selectedValue?.label, 'episodeName')
                                        }}
                                    />
                                    {/* <BasicTextField
                                        id="episodeName"
                                        placeholder="Episode Name"
                                        value={this.props.budgetSelectedFilters?.episodeName || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('episodeName', 'episodeName', e.target.value, 'episodeName');
                                            this.props.updateListState(e.target.value, 'episodeName', 'budgetSelectedFilters')
                                        }}

                                    /> */}
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="episode"
                                            placeholder="Episode #"
                                            value={this.props.budgetSelectedFilters?.episode || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('episode', 'episode', e.target.value, 'episode');
                                                this.props.updateListState(e.target.value, 'episode', 'budgetSelectedFilters')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow id="episode_Name">
                                    <BasicTextField
                                        id="description"
                                        placeholder="Description"
                                        value={this.props.budgetSelectedFilters?.description || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('description', 'description', e.target.value, 'description');
                                            this.props.updateListState(e.target.value, 'description', 'budgetSelectedFilters')
                                        }}
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id="union"
                                            placeHolderText="- Union -"
                                            value={this.props.budgetSelectedFilters?.union || null}
                                            options={this.state.filterList?.unionList}
                                            onChange={(e) => {
                                                let selectedValue = this.state.filterList?.unionList?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'union', 'budgetSelectedFilters')
                                                this.handleFilterListSelection('union', selectedValue?.value, selectedValue?.label, 'union')
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id="piolt-other/series"
                                            placeHolderText="- piolt-other/series -"
                                            value={this.props.budgetSelectedFilters?.pilotOther || null}
                                            options={this.state.pilotAndSeriesList}
                                            onChange={(e) => {
                                                let selectedValue = this.state.pilotAndSeriesList.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'pilotOther', 'budgetSelectedFilters')
                                                this.handleFilterListSelection('pilotOther', selectedValue?.value, selectedValue?.label, 'pilotOther')
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <BasicTextField
                                            id="shortdate"
                                            placeholder="- Shoot Date -"
                                            value={this.props.budgetSelectedFilters?.shootDate || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('shootDate', 'shootDate', e.target.value, 'shootDate');
                                                this.props.updateListState(e.target.value, 'shootDate', 'budgetSelectedFilters')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                        {/* <DateField
                                            id="shortdate"
                                            onHoverDateText="- Short Date -"
                                            placeHolderText="- Short Date -"
                                            value={this.props?.budgetSelectedFilters?.shootDate || null}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('shootDate', 'shootDate', e.target.value, 'shootDate');
                                                this.props.updateListState(e.target.value, 'shootDate', 'budgetSelectedFilters')
                                            }}
                                        /> */}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <BasicTextField
                                            id="recordingdate"
                                            placeholder="- Recording Date -"
                                            value={this.props.budgetSelectedFilters?.recordingDate || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('recordingDate', 'recordingDate', e.target.value, 'recordingDate');
                                                this.props.updateListState(e.target.value, 'recordingDate', 'budgetSelectedFilters')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                        {/* <DateField
                                            id="recordingdate"
                                            onHoverDateText="- Recording Date -"
                                            placeHolderText="- Recording Date -"
                                            value={this.props.budgetSelectedFilters?.recordingDate || null}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('recordingDate', 'recordingDate', e.target.value, 'recordingDate');
                                                this.props.updateListState(e.target.value, 'recordingDate', 'budgetSelectedFilters')
                                            }}
                                        /> */}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="lh-1">
                                    <BasicTextField
                                        id="location"
                                        placeholder="- Location -"
                                        value={this.props?.budgetSelectedFilters?.location || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('location', 'location', e.target.value, 'location');
                                            this.props.updateListState(e.target.value, 'location', 'budgetSelectedFilters')
                                        }}
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol>
                        <MDBRow>
                            <div className="display-input">
                                <MDBCol id="zipcode-Field" md={4}>
                                    <SelectField
                                        id="gt"
                                        // placeHolderText="- gt -"
                                        value={this.props?.budgetSelectedFilters?.gt1 || null}
                                        options={this.state.gtOptionsList}
                                        onChange={(e) => {
                                            let selectedValue = this.state.gtOptionsList.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'gt1', 'budgetSelectedFilters')
                                            this.handleFilterListSelection('gt1', selectedValue?.value, selectedValue?.label, 'gt1')
                                        }}
                                        fieldValid={this.props?.budgetSelectedFilters?.performerCost && !this.props?.performerValid && !this.props.budgetSelectedFilters?.gt1}
                                        inValidInput={!this.props?.performerValid ? "This field cannot be empty" : null}
                                    />
                                </MDBCol>
                                <MDBCol id="city-Field" md={8}>
                                    <BasicTextField
                                        id="performerCost"
                                        placeholder="- $ Tot.Performer Cost -"
                                        value={this.props?.budgetSelectedFilters?.performerCost || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('performerCost', 'performerCost', e.target.value, 'performerCost');
                                            this.props.updateListState(e.target.value, 'performerCost', 'budgetSelectedFilters')
                                        }}
                                        fieldValid={this.props?.budgetSelectedFilters?.gt1 && !this.props?.gt1_valid && !this.props.budgetSelectedFilters?.performerCost}
                                        inValidInput={!this.props?.gt1_valid ? "This field cannot be empty" : null}
                                        InputProps={
                                            {
                                                startAdornment: (
                                                    <InputAdornment position="start" size="small">
                                                        {'$'}
                                                    </InputAdornment>
                                                ),
                                            }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBCol>
                            </div>
                        </MDBRow>
                        <MDBRow>
                            <div className="display-input">
                                <MDBCol id="zipcode-Field" md={4}>
                                    <SelectField
                                        id="gt2"
                                        // placeHolderText="- gt1 -"
                                        value={this.props.budgetSelectedFilters?.gt2 || null}
                                        options={this.state.gtOptionsList}
                                        onChange={(e) => {
                                            let selectedValue = this.state.gtOptionsList.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'gt2', 'budgetSelectedFilters')
                                            this.handleFilterListSelection('gt2', selectedValue?.value, selectedValue?.label, 'gt2')
                                        }}
                                        fieldValid={this.props?.budgetSelectedFilters?.recordCost && !this.props?.recordingValid && !this.props.budgetSelectedFilters?.gt2}
                                        inValidInput={!this.props?.recordingValid ? "This field cannot be empty" : null}
                                    />
                                </MDBCol>
                                <MDBCol id="city-Field" md={8}>
                                    <BasicTextField
                                        id="performerCost2"
                                        placeholder="- $ Tot.Recording Cost -"
                                        value={this.props.budgetSelectedFilters?.recordCost || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('recordCost', 'recordCost', e.target.value, 'recordCost');
                                            this.props.updateListState(e.target.value, 'recordCost', 'budgetSelectedFilters')
                                        }}
                                        fieldValid={this.props?.budgetSelectedFilters?.gt2 && !this.props?.gt2_valid && !this.props.budgetSelectedFilters?.recordCost}
                                        inValidInput={!this.props?.gt2_valid ? "This field cannot be empty" : null}
                                        InputProps={
                                            {
                                                startAdornment: (
                                                    <InputAdornment position="start" size="small">
                                                        {'$'}
                                                    </InputAdornment>
                                                ),
                                            }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBCol>
                            </div>
                        </MDBRow>
                        <MDBRow>
                            <div className="display-input">
                                <MDBCol id="zipcode-Field" md={4}>
                                    <SelectField
                                        id="gt3"
                                        // placeHolderText="- gt2 -"
                                        value={this.props.budgetSelectedFilters?.gt3 || null}
                                        options={this.state.gtOptionsList}
                                        onChange={(e) => {
                                            let selectedValue = this.state.gtOptionsList.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'gt3', 'budgetSelectedFilters')
                                            this.handleFilterListSelection('gt3', selectedValue?.value, selectedValue?.label, 'gt3')
                                        }}
                                        fieldValid={this.props?.budgetSelectedFilters?.sessionCost && !this.props?.sessionCostValid && !this.props.budgetSelectedFilters?.gt3}
                                        inValidInput={!this.props?.sessionCostValid ? "This field cannot be empty" : null}
                                    />
                                </MDBCol>
                                <MDBCol id="city-Field" md={8}>
                                    <BasicTextField
                                        id="performerCost3"
                                        placeholder="$ Tot.Session Cost"
                                        value={this.props.budgetSelectedFilters?.sessionCost || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('sessionCost', 'sessionCost', e.target.value, 'sessionCost');
                                            this.props.updateListState(e.target.value, 'sessionCost', 'budgetSelectedFilters')
                                        }}
                                        fieldValid={this.props?.budgetSelectedFilters?.gt3 && !this.props?.gt3_valid && !this.props.budgetSelectedFilters?.sessionCost}
                                        inValidInput={!this.props?.gt3_valid ? "This field cannot be empty" : null}
                                        InputProps={
                                            {
                                                startAdornment: (
                                                    <InputAdornment position="start" size="small">
                                                        {'$'}
                                                    </InputAdornment>
                                                ),
                                            }
                                        }
                                        onKeyUp={(e) =>{
                                            if(e.keyCode === 13){
                                                this.props?.handleFilterSearch()
                                            }
                                        }}
                                    />
                                </MDBCol>
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
});
