export const licenseTrackingFilter = {
    licensorName: null,
    type: null,
    licenseeRep: null,
    trackingStatus: null,
    sOrM: null,
    daysPending: null,
    status: null,
    groupBy: null,
    show: null,
    episodeName: null,
    season: null,
    showYear: null,
    episode: null
}

export const licenseTrackingConfig = {
    show_search_ids: null,
    season_search_ids: null, 
    show_year_search_text: null,
    episode_title_search_text: null,
    episode_no_search_text: null,
    type_search_ids: null,
    licensor_search_ids: null,
    tracking_status_search_ids: null,
    status_search_ids: null,
    licensee_rep_search_ids: null,
    licensor_type_search_ids: null,
    days_pending_search_text: null,
    group_by_clause: null,
    order_by: null,
    order_by_clause: "Show",
    page_count: 25,
    page_no: 1
}

export const licenseTrackingTblConfig = {
    headings: [{
        headingLabel: "Show",
        icon: "",
        width: "13%"
    },
    {
        headingLabel: "Season",
        icon: "",
        width: "7%"
    },
    {
        headingLabel: "Episode",
        icon: "",
        width: "7%"
    },
    {
        headingLabel: "Licensor",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Licensee Rep",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Composition",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "S/M",
        icon: "",
        width: "5%"
    },
    {
        headingLabel: "Share",
        icon: "",
        width: "5%"
    },
    {
        headingLabel: "Last Status",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Last Act. Dt",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Lic. Rec. Dt",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "",
        icon: "",
        width: "3%"
    }
    ], dataNodes: ["show_name", "season_name", "episode_number", "licensor_name", "licensee_rep", "composition", "licensor_type", "share", "last_status", "last_action_date", "license_request_date"],
    actions: ["share"],
    primaryKey: "show_season_id",
    hyperlinks: ["season_name", "episode_number"]
}

export const filterConfig = {
    show: { id: 'show_id', name: 'show_name'},
    season: { id: 'season_id', name: 'season_name' },
    user: { id: 'user_id', name: 'full_name'},
    licensor: {id: "licensor_id", name: "licensor_name"}
}

export const statusOption = [
    { label: "Select", value: null },
    { label: "Pending", value: "1" },{ label: "Overdue", value: "2" },{ label: "Closed", value: "3" }
]

export const type = [{ label: "Select", value: null }, { label: "Main Title CR's", value: "1" }, { label: "Clearance Records", value: "0" }]

export const smOptions = [
    { label: "Select", value: null },
    { label: "S", value: 1 },
    { label: "M", value: 2 },
    {label: "S/M", value: 3 }
]

export const groupByOptions = [
    { label: "Select", value: null },
    {label: "Show", value: "Show"},
    {label: "Season", value: "Season"},
    {label: "Licensor", value: "Licensor"},
    {label: "Licensee Rep", value: "Licensee Rep"},
    {label: "Composition", value: "Composition"},
    {label: "S/M", value: "S/M"}
]
