export const clearanceRecordsFilter = {
    id:null,
    selectedType:null,
    selectedShowType:null,
    selectedTerritory:null,
    selectedShow:null,
    selectedStatus:null,
    selectedSeason:null,
    selectedEpisode:null,
    selectedSong:null,
    showYr:null,
    user:null,
    episodeName:null,
    licensorUser:null,
    episode:null,
    airDate:null,
    selectedUses:null,
    scene:null,
    selectedCLRecord:null,
    songTitle:null,
    selectedPerformer:null,
    writer:null,
    duration:null,
    initialRightsList:null,
    initialRightsTerm:null,
    initialRightsTerritory:null,
    selectedAltInitialRight:null,
    altRightsTerm:null,
    altRightsTerritory:null,
    licensorName:null,
    licensorPayee:null,
    selectedLicensorStatus:null,
    quoteReqDate:null,
    licReqDate:null,
    providesOwnLic:null,
    activeInactive:null,
    checkNo:null,
    relianceLtrRcvr:null,
    notePaid: null,
    intLicChanges: null,
    confirmDate: null
}
export const clearanceRecordsTblConfig = {
    headings: [{
        headingLabel: "Show/Season",
        icon: "",
        width: "15%",
        dataNode:"show_season_name"
    },
    {
        headingLabel: "Episode#",
        icon: "",
        width: "9%",
        dataNode:"episode_number"
    },
    {
        headingLabel: "Song",
        icon: "",
        width:"15%"
    },
    {
        headingLabel: "Initial Rights",
        icon: "",
        width:"12%"
    },
    {
        headingLabel: "Licensor",
        icon: "",
        width:"12%"
    },
    {
        headingLabel: "Licensee Rep",
        icon: "",
        width:"10%"
    },
    {
        headingLabel: "S/M",
        icon: "",
        width:"5%"
    },
    
    {
        headingLabel: "Share",
        icon: "",
        width:"6%"
    },
    {
        headingLabel: "Status",
        icon: "",
        width:"6%"
    }, 
    {
        headingLabel: "Clearance Id",
        icon: "",
        width: "7%",
        dataNode: "clearance_id"
    },   
    {
        headingLabel: " ",
        icon: "",
        width:"3%"
    }],
    dataNodes: ["show_season_name", "episode_number", "song_by_performer","initial_rights_name","licensor_name","licensee_rep_name","licensor_type","share","status","clearance_id"],
    primaryKey: "clearance_id",
    actions: ["share"],
    hyperlinks: ["show_season_name", "episode_number"],
}
export const filterClearanceConfig = {
    show: {id: 'show_id', name: 'show_name'},
    season: { id: 'season_id', name: 'season_name', is_active: 'is_active' },
    status: { id: 'id', name: 'name', is_active: 'is_active' },
    //uses: { id: 'id', name: 'name', is_active: 'is_active' },
    CLClearanceRep:{ id: 'id', name: 'name', is_active: 'is_active' },
    user: {id: 'user_id', name: 'full_name', is_active: 'is_active'},
    term: {id: 'term_id', name: 'term_name', is_active: 'is_active'},
    territory: {id: 'territory_id', name: 'territory_abbr', is_active: 'is_active'},
    rights: { id: 'right_id', name: 'right_abbr', is_active: 'is_active' },
    uses: { id: 'right_id', name: 'right_abbr', is_active: 'is_active' },
    performers: { id: 'performer_id', name: 'performer_name' },
    writers: { id: 'writer_id', name: 'writer_name', is_active: 'is_active' },
    licensors: { id: 'licensor_id', name: 'licensor_name', is_active: 'is_active' },
    licensorsPayee: { id: 'licensor_payee_id', name: 'payee_company', is_active: 'is_active' },
    //songs:{id:'song_id',name:'song_name'}
}

export const clearenceRecordJSON = {
type_search_ids:null ,
show_search_text:null,
main_clearance_id_search_text:null,
show_search_ids:null,
season_search_ids:null,
episode_title_search_text:null,
episode_no_search_text:null,
air_date_search_text:null,
showtype_search_id:null,
scene_search_text:null,
record_options_search_ids:null,
territory_search_ids:null,
status_search_ids:null,
clearance_rep_search_ids:null,
licensee_rep_search_ids:null,
use_search_ids:null,
song_search_text:null,
writer_search_ids:null,
performer_search_ids:null,
options_search_ids:null,
duration_search_text:null,
init_right_search_ids:null,
init_term_search_ids:null,
init_territory_search_ids:null,	
alt_init_right_search_ids:null,
alt_init_term_search_ids:null,
alt_init_territory_search_ids:null,
option_right_search_ids:null,
option_term_search_ids:null,
option_territory_search_ids:null,
licensor_search_ids:null,
licensor_type_search_ids:null,
licensor_status_search_ids:null,
is_provides_own_lic_flag:null,
is_active_flag:null,
reliance_ltr_rec_flag:null,
int_lic_w_change_flag:null,
payee_search_ids:null,
quote_date_search_text:null,
lic_date_search_text:null,
confirm_date_search_text:null,
check_search_text:null,
note_paid_flag: null,
in_batch:null,
order_by:null,
order_by_clause: "Show/Season",
page_count: 25,
page_no: 1
}