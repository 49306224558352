import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import DateField from "../../SharedComponents/DateField/DateField"
import { ClearTrackServiceBaseUrl } from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import { filterConfig, statusOptions } from './Config';
import axios from 'axios';

export default withUserContext(class CueSheets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterSearchList: [],
            clearFilters: this.props?.clearFilters,
            searchCancelToken: null,
            sortBy: "Show",
            selectedShowValue: null,
            filterList: {
                showOptions: [],
                seasonOptions: [],
                userOptions: [],
                statusOptions: statusOptions,
                showBasedSeasonList: []
            }
        }
    }
    componentDidMount() {
        document.title = 'Cue Sheet'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.getEntity("SEASON", filterConfig.season, "seasonOptions");
        this.getUserList("USER", filterConfig.user, "userOptions");
        this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.getEntity("SEASON", filterConfig.season, "seasonOptions");
            this.getUserList("USER", filterConfig.user, "userOptions");
            this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Show" })
        }
        if (this.props?.userContext.dashboardName === "Cue Sheets" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    getEntity = (entity, config, node) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        label: item[config.name]
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }

    getUserList = (entity, config, node) => {
        let allUsers  = []
        ClearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl + '/userAll', this.props.userContext?.active_tenant?.tenant_id, null).then((response) => {
            let validCueSheetRepRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)', 'Music Clearance (Basic)','Line Producer']
            let validCueSheetRepUsers = []
            allUsers = [...response?.data]
            for (let i = 0; i < response?.data?.length; i++) {
              for (let j = 0; j < response?.data[i]?.tenants?.length; j++) {
                if (response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
                  for (let k = 0; k < response?.data[i]?.tenants[j]?.division_role?.length; k++) {
                      if (validCueSheetRepRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                        validCueSheetRepUsers.push({ value: response?.data[i].user_id, label: response?.data[i].full_name, is_active: response?.data[i].is_active })
                      }
                  }
                }
              }
            }
            let uniqueArr = validCueSheetRepUsers.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
                )
            uniqueArr.unshift({ label: "Select", value: null })
            this.updateListState(uniqueArr, node, "filterList");
          });
      };

    getShows = (entity, config, node, searchString) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": searchString,
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name]
                    }));
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
        this.props.updateListState(null, 'season', 'cueSheetsFilter')
        this.handleFilterListSelection('season', null, null, 'season')
        ClearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/seasonsBasedOnShows?show_id=${newValue.value}`,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    let selectedShow = response?.data[0]?.seasons?.find(
                        (item) => response?.data[0]?.show_id === newValue.value
                    )?.show_season_id;
                    let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                        (item, index, data) =>
                            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                    ) : [];
                    let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                        value: item.season_id,
                        label: item.season_name,
                    })) : [];
                    let seasonBasedEpisodes = response?.data[0]?.seasons;
                    this.setState({ seasonBasedEpisodes })
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                    }
                })
            .catch(error => {
                console.log("error--", error)
            })
    }


    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    handleFilterListSelection = (type, val, name, node) => {
        let filteredCueSheets = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = filteredCueSheets.filter((item, index) => {
            if (item.type === type) {
                return filteredCueSheets[index] = newFilterValue
            }
        })
        if (check.length === 0) {
            filteredCueSheets.push(newFilterValue)
        }
        this.setState({ filterSearchList: filteredCueSheets })
        let selectedCueSheetFilters = {
            show_search_ids: filteredCueSheets.find((item) => item.type === "show")?.value || null,
            season_search_ids: filteredCueSheets.find((item) => item.type === "season")?.value || null,
            episode_title_search_text: filteredCueSheets.find((item) => item.type === "episodeName")?.value || null,
            episode_no_search_text: filteredCueSheets.find((item) => item.type === "episode")?.label || null,
            cue_sheet_for_ids: filteredCueSheets.find((item) => item.type === "cueSheetFor")?.value || null,
            status_search_ids: filteredCueSheets.find((item) => item.type === "status")?.value || null,
            date_finalized_search_text: filteredCueSheets.find((item) => item.type === "dateFinalized")?.label || null,
            show_year_search_text: filteredCueSheets.find((item) => item.type === "showYr")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: this.props?.rowsPerPage,
            page_no: this.props?.page > 0 ? this.props?.page : 1
        }
        this.props.updateFilteredList(filteredCueSheets, selectedCueSheetFilters, type)
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        ClearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };

    render() {
        let { cueSheetsFields } = this.props;
        return (
            <div className='filterFieldsContainer'>
                <MDBRow>
                    <MDBCol md={10}>
                        <MDBRow>
                            <MDBCol>
                                <MDBRow className="mt-5">
                                    <SearchSelectField
                                        id="show"
                                        placeholder="- Show -"
                                        width="100%"
                                        searchSelect={true}
                                        multiple={false}
                                        detail_selected={this.props?.cueSheetsFilter?.show ? this.state.selectedShowValue : null}
                                        options={this.state.filterList?.showOptions || []}
                                        valueSelected={(e, newValue) => {
                                            if (newValue) {
                                                this.setState({ selectedShowValue: newValue })
                                                this.selectedShowBasedSeason(newValue)
                                                //let selectedShow = this.state.filterList?.showOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                                this.props.updateListState(newValue != null ? newValue.value : null, 'show', 'cueSheetsFilter')
                                                this.handleFilterListSelection("show", (newValue != null ? newValue?.value : null), newValue != null ? newValue?.text : null, "show")

                                            } else {
                                                this.setState({ selectedShowValue: null }, () => {
                                                    if (!this.state.selectedShowValue) {
                                                        this.props.updateListState(null, 'season', 'cueSheetsFilter')
                                                        this.handleFilterListSelection('season', null, null, 'season')
                                                        this.props.updateListState(null, 'episodeName', 'cueSheetsFilter')
                                                        this.handleFilterListSelection('episodeName', null, null, 'episodeName')
                                                    }
                                                })
                                                this.props.updateListState(null, 'show', 'cueSheetsFilter')
                                                this.handleFilterListSelection("show", null, null, "show")

                                            }
                                        }}
                                        searchText={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                this.getShows("SHOW", filterConfig.show, "showOptions", ev.target.value);
                                            }
                                        }}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id="season"
                                            placeHolderText="- Season -"
                                            value={this.props?.cueSheetsFilter?.season}
                                            options={this.props?.cueSheetsFilter?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonOptions || []}
                                            onChange={(e) => {
                                                let selectedSeason = (this.props?.cueSheetsFilter?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonOptions)?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'season', 'cueSheetsFilter')
                                                this.handleFilterListSelection("season", selectedSeason?.value, selectedSeason?.label, "season")
                                                let selectedShowSeason =
                                                    this.state.seasonBasedEpisodes?.find(
                                                        (item) => item.season_id === e.target.value
                                                    )?.show_season_id;
                                                this.setState(
                                                    { show_season_id: selectedShowSeason },
                                                    () => {
                                                        this.getShowBasedEpisodes(this.state.show_season_id);
                                                    }
                                                );
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol md={5}>
                                        <BasicTextField
                                            id="showYr"
                                            placeholder="- Show Yr -"
                                            value={this.props?.cueSheetsFilter?.showYr || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection("showYr", "showYr", e.target.value, "showYr")
                                                this.props.updateListState(e.target.value, 'showYr', 'cueSheetsFilter')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol>
                                <MDBRow>
                                    <SelectField
                                        id="episode"
                                        // label={"Seasons"}
                                        placeHolderText="- Episode Name -"
                                        options={this.props?.cueSheetsFilter?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption || []}
                                        width="80%"
                                        value={this.props?.cueSheetsFilter?.episodeName || null}
                                        onChange={(e) => {
                                            let selectedValue = (this.props?.cueSheetsFilter?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption)?.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'episodeName', 'cueSheetsFilter')
                                            this.handleFilterListSelection('episodeName', selectedValue?.value, selectedValue?.label, 'episodeName')
                                        }}
                                    />
                                    {/* <BasicTextField
                                        id="episodeName"
                                        placeholder="- Episode Name -"
                                        value={this.props?.cueSheetsFilter?.episodeName || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection("episodeName", "episodeName", e.target.value, "episodeName")
                                            this.props.updateListState(e.target.value, 'episodeName', 'cueSheetsFilter')
                                        }}
                                    /> */}
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="episode#"
                                            placeholder="- Episode # -"
                                            value={this.props?.cueSheetsFilter?.episode || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection("episode", "episode", e.target.value, "episode")
                                                this.props.updateListState(e.target.value, 'episode', 'cueSheetsFilter')
                                            }}
                                            onKeyUp={(e) =>{
                                                if(e.keyCode === 13){
                                                    this.props?.handleFilterSearch()
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol>
                                <SelectField
                                    id="cueSheetFor"
                                    value={this.props?.cueSheetsFilter?.cueSheetFor}
                                    options={this.state.filterList?.userOptions || []}
                                    placeHolderText={"- CueSheet For -"}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.userOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'cueSheetFor', 'cueSheetsFilter')
                                        this.handleFilterListSelection("cueSheetFor", selectedValue?.value, selectedValue?.label, "cueSheetFor")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <SelectField
                                    id="status"
                                    placeHolderText="- Status -"
                                    value={this.props?.cueSheetsFilter?.status}
                                    options={this.state.filterList?.statusOptions || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.statusOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'status', 'cueSheetsFilter')
                                        this.handleFilterListSelection("status", selectedValue?.value, selectedValue?.label, "status")
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="mt-5">
                                <DateField
                                    id="dateFinalized"
                                    onHoverDateText="- Date Finalized -"
                                    placeholder="- Date Finalized -"
                                    value={this.props?.cueSheetsFilter?.dateFinalized || null}
                                    // onChange={(e) => this.props?.onChangeHandler("dateFinalized", e.target.value)}
                                    onChange={(e) => {
                                        this.handleFilterListSelection("dateFinalized", "dateFinalized", e.target.value, "dateFinalized")
                                        this.props.updateListState(e.target.value, 'dateFinalized', 'cueSheetsFilter')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>

            </div>
        )
    }
});
