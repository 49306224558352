export const agentListConfig = {
    headings: [
        {
            headingLabel: "FullName",
            inputType: "text",
            width: "15%",
            icon: ""
        },
        {
            headingLabel: "Email",
            inputType: "text",
            width: "12%",
            icon: ""
        },
        {
            headingLabel: "Phone",
            inputType: "text",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "OtherPhone",
            inputType: "text",
            width: "10%",
            icon: ""
        },

        {
            headingLabel: "Assistant",
            inputType: "text",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Primary",
            inputType: "checkbox",
            width: "6%",
            icon: ""
        },
        {
            headingLabel: "CC",
            inputType: "checkbox",
            width: "5%",
            icon: ""
        },
        {
            headingLabel: "Inactive",
            inputType: "checkbox",
            width: "6%",
            icon: ""
        },
        {
            headingLabel: "Cre. Contact",
            inputType: "checkbox",
            width: "9%",
            icon: ""
        },
        {
            headingLabel: "Cl. Contact",
            inputType: "checkbox",
            width: "9%",
            icon: ""
        },
    ],
    dataNodes: ["full_name", "email", "phone", "other_phone", "assistant_name", "is_primary", "is_cc", "is_inactive", "is_cre_contact", "is_cl_contact"],
    primaryKey: "licensor_contact_id",
    // toggleFields: ["is_primary", "is_cc", "lc_is_active", "is_cre_contact", "is_cl_contact"],
    actions: ["pen", "trash-alt"],
    nodeFields: [
        {
            column: "is_primary",
            icon: "check"
        },
        {
            column: "is_cc",
            icon: "check"
        },
        {
            column: "is_inactive",
            icon: "check"
        },
        {
            column: "is_cre_contact",
            icon: "check"
        },
        {
            column: "is_cl_contact",
            icon: "check"
        },
    ],
    inlineEdits: [
        {
            dataNode: "full_name",
            width: "14%"
        },
        {
            dataNode: "email",
            width: "11%",
            validateMethod : 'validateEmail',
            validateMessage : 'Please enter valid Email'
        },
        {
            dataNode: "phone",
            width: "9%",
            // validateMethod : 'validateAllPhone',
            // validateMessage : 'Please enter valid phone number',
            placeholder:"(___) ___-____"
        },
        {
            dataNode: "other_phone",
            width: "9%",
            // validateMethod : 'validateAllPhone',
            // validateMessage : 'Please enter valid phone number',
            placeholder:"(___) ___-____"
        },
        {
            dataNode: "assistant_name",
            width: "11%"
        },
        {
            dataNode: "is_primary",
            width: "5.5%"
        },
        {
            dataNode: "is_cc",
            width: "4%"
        },
        {
            dataNode: "is_inactive",
            width: "5.5%"
        },
        {
            dataNode: "is_cre_contact",
            width: "9.5%"
        },
        {
            dataNode: "is_cl_contact",
            width: "9.5%"
        }
    ],
    hyperlinks: [""]

};
export const allContactList = [
    { 
        id: 1,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 2,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 3,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 4,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 5,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 6,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 7,
        full_name: "Full Name",
        email: "Email",
        phone: "Phone",
        other_phone: "Other Phone",
        assistant_name: "Assistant",
        is_primary: 1,
        is_cc: 0,
        lc_is_active: 1,
        is_cre_contact: 0,
        is_cl_contact: 1
    },
    {
        id: 8,
        full_name: "#to add + icon to add record in the table ",
        email: "#to add + icon to add record in the table",
        phone: "#to add + icon to add record in the table",
        other_phone: "#to add + icon to add record in the table",
        assistant_name: "#to add + icon to add record in the table",
        is_primary: "#to add + icon to add record in the table",
        is_cc: "",
        lc_is_active: "",
        is_cre_contact: "",
        is_cl_contact: ""
    },
]

export const LicensorPayeeConfig = {
    headings: [
        {
            headingLabel: "Payee Name",
            inputType: "searchselect",
            selectOptions: [],
            width: "25%",
            icon: ""

        },
        {
            headingLabel: "Default?",
            inputType: "checkbox",
            width: "17%",
            icon: ""
        },
        {
            headingLabel: "Auto-pop?",
            inputType: "checkbox",
            width: "17%",
            icon: ""
        },
        {
            headingLabel: "Tax ID/SSN",
            inputType: "label",
            width: "17%",
            icon: ""
        },
        {
            headingLabel: "Vendor #",
            inputType: "label",
            width: "15%",
            icon: ""
        }],

    dataNodes: ["payee_remit_to", "is_default", "is_auto_pop", "tax_ssn", "vendor"],
    searchFields: [{
        label: "payee_remit_to",
        hyperlink: false
    }],
    primaryKey: "licensor_payee_id",
    // toggleFields: ["is_default", "is_auto_pop"],
    actions: ["pen", "trash-alt"],
    nodeFields: [
        {
            column: "is_default",
            icon: "check"
        },
        {
            column: "is_auto_pop",
            icon: "check"
        },
    ],

    inlineEdits: [
        {
            dataNode: "payee_remit_to",
            width: "24.4%"
        },
        {
            dataNode: "is_default",
            width: "16.5%"
        },
        {
            dataNode: "is_auto_pop",
            width: "16.5%"
        },
        {
            dataNode: "tax_ssn",
            width: "16%"
        },
        {
            dataNode: "vendor",
            width: "15%"
        },
    ],
    hyperlinks: [""]
};

export const AllPayeeList = [
    {
        id: 1,
        PayeeName: "[Payee Name]",
        Default: 1,
        Autopop: 0,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 2,
        PayeeName: "[Payee Name]",
        Default: 0,
        Autopop: 1,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 3,
        PayeeName: "[Payee Name]",
        Default: 1,
        Autopop: 0,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 4,
        PayeeName: "[Payee Name]",
        Default: 0,
        Autopop: 1,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 5,
        PayeeName: "[Payee Name]",
        Default: 1,
        Autopop: 0,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 6,
        PayeeName: "[Payee Name]",
        Default: 1,
        Autopop: 1,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 7,
        PayeeName: "[Payee Name]",
        Default: 0,
        Autopop: 1,
        TaxIDSSN: "[Tax ID/SSN]",
        Vendor: "[Vendor]",
    },
    {
        id: 8,
        PayeeName: "[#to add + icon to add record in the table]",
        Default: 0,
        Autopop: 0,
        TaxIDSSN: "[#to add + icon to add record in the table]",
        Vendor: "[#to add + icon to add record in the table]",
    }
]

export const ClRecordList = {
    headings: [
        {
            headingLabel: "Program",
            width: "15%",
            icon: ""
        },
        {
            headingLabel: "Season",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Episode #",
            icon: "25%"
        },
        {
            headingLabel: "Composition",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Use",
            width: "5%",
            icon: ""
        },
        {
            headingLabel: "Status",
            icon: "5%"
        },
        {
            headingLabel: "Rights",
            width: "15%",
            icon: ""
        },
        {
            headingLabel: "Lic. Fee",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Song Fee",
            width: "8%",
            icon: ""
        },
    ],

    dataNodes: ["show_name", "season_name", "episode", "song_title", "use", "status", "rights", "lic_fee", "song_fee"],
    primaryKey: "id",
    hyperlinks: ["show_name", "season_name", "episode"],
    actions: ["share"]
};

export const AllCLRecords = [
    {
        id: 1,
        Program: "Shameless",
        Season: "2017-2018",
        Composition: "U13.14552",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
    {
        id: 2,
        Program: "[Show Name]",
        Season: "[Show Years]",
        Composition: "[Episode #]",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
    {
        id: 3,
        Program: "[Show Name]",
        Season: "[Show Years]",
        Composition: "[Episode #]",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
    {
        id: 4,
        Program: "[Show Name]",
        Season: "[Show Years]",
        Composition: "[Episode #]",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
    {
        id: 5,
        Program: "[Show Name]",
        Season: "[Show Years]",
        Composition: "[Episode #]",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
    {
        id: 6,
        Program: "[Show Name]",
        Season: "[Show Years]",
        Composition: "[Episode #]",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
    {
        id: 7,
        Program: "[Show Name]",
        Season: "[Show Years]",
        Composition: "[Episode #]",
        Episode: "[Composition Name] by [Performer(s)]",
        Use: "[Use]",
        Status: "[Status]",
        Rights: "[Rights]",
        LicFee: "$[Lic. Fee]",
        SongFee: "$[Song Fee]"

    },
]

export const licenseEntityInfoConfig = {
    headings: [
        {
            headingLabel: "Name",
            inputType: "text",
            width: "40%",
            icon: ""
        },
        {
            headingLabel: "Email",
            inputType: "text",
            width: "40%",
            icon: ""
        },
        {
            headingLabel: "CC",
            inputType: "checkbox",
            width: "10%",
            icon: ""
        }
    ],
    dataNodes: ["licency_contact_name", "licency_contact_email", "is_cc"],
    primaryKey: "licency_contact_info_id",
    toggleFields: ["is_cc"],
    actions: ["pen", "trash-alt"],
    inlineEdits: [
        {
            dataNode: "licency_contact_name",
            width: "38%"
        },
        {
            dataNode: "licency_contact_email",
            width: "40%",
            validateMethod: 'validateEmail',
            validateMessage: "Please enter valid Email"
        },
        {
            dataNode: "is_cc",
            width: "10%"
        }
    ]
}

export const licenseEntityList = [
    { 
        id: 1,
        name: "Johnny Appleseed",
        email: "Johnny.Appleseed@alias.com",
        is_cc: 1
    }
]

export const AddressesConfig = {
    headings:[
        {
            headingLabel: "Address_1",
            inputType: "text",
            width: "20%",
            icon: ""
        },
        {
            headingLabel: "Address_2",
            inputType: "text",
            width: "20%",
            icon: ""
        },
        {
            headingLabel: "City",
            inputType: "text",
            width: "15%",
            icon: ""
        },
        {
            headingLabel: "State",
            inputType: "text",
            width: "15%",
            icon: ""
        },
        {
            headingLabel: "Country",
            inputType: "text",
            width: "15%",
            icon: ""
        },
        {
            headingLabel: "Zip",
            inputType: "text",
            width: "15%",
            icon: ""
        }
    ],
    dataNodes: ["address_1", "address_2", "city","state","country","zip"],
    primaryKey: "licensor_address_history_id"
}

export const mockJson = {
    "licensor_id": null,
    "licensor_name": null,
    "licensor_akas": [],
    "address_1": null,
    "address_2": null,
    "address_history_list" : [],
    "city": null,
    "contacts_list": [],
    "country": null,
    "country_id": 1,
    "history_address_1": null,
    "history_address_2": null,
    "history_city": null,
    "history_country": null,
    "history_state": null,
    "history_zip": null,
    "is_internal_license_with_changes": 0,
    "is_provides_own_license": 0,
    "is_reliance_letter_receiver": 0,
    "notes": null,
    "warning_notes": null,
    "zip": null,
    "licensor_payees": [],
    "licensor_contacts":[],
    "is_active": 1
}

export const mockAddressHistoryList = {
    "address_1": null,
    "address_2": null,
    "city": null,
    "country": null,
    "licensor_address_history_id": null,
    "state": null,
    "zip": null
}

export const mock_akas_list = {
    "licensor_aka_id": null,
    "aka": null
}

export const mock_contacts_list = {
    "assistant_name": null,
    "email": null,
    "full_name": null,
    "is_cc": 0,
    "is_cl_contact": 0,
    "is_cre_contact": 0,
    "is_primary": 0,
    "lc_is_active": 0,
    "licensor_contact_id": null,
    "other_phone": null,
    "phone": null
}

export const mock_payees_list = {
    "is_auto_pop": 0,
    "is_default": 0,
    "licensor_payee_id": null,
    "lp_is_active": 0,
    "payee_company": null,
    "payee_id": null,
    "ssn": null,
    "tax": null,
    "vendor": null
}

